<template>
  <b-modal v-model="show" title="Inquiry for VA Dipay user" hide-footer @hide="onHide">
    <el-descriptions class="margin-top mb-3" title="" :column="1" border>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          Name
        </template>
        {{ selectedUser.full_name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-phone"></i>
          Phone Number
        </template>
        {{ selectedUser.phone_number }}
      </el-descriptions-item>
    </el-descriptions>
    <b-form @reset="onHide" class="mt-2">
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="35%">Bank Name</th>
              <th width="30%"> VA Number</th>
            </tr>
          </thead>
          <tbody v-loading="isLoading">
          <tr v-for="(item, i) in inquiry_va" :key="i">
            <td>{{ item?.name }}</td>
            <td>{{ item?.virtual_account_no }}</td>
          </tr>
        </tbody>
        </table>
      </div>
    </b-form>
    <div class="d-flex float-right">
      <el-button @click="onHide" size="small" class="mr-2">Close</el-button>
    </div>
  </b-modal>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { INQUIRY_VA
} from '@/store/modules/app_user';

export default {
  name: 'SingleImportPrivate',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    selectedUser: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
    };
  },
  data() {
    return {
      show: false,
      loading: {
        company: false,
        submit: false,
      },
      isLoading: false,
      timeoutSearchCompany: null,
      selected_company: {},
      inquiry_va: {}
    };
  },
  methods: {
    onHide() {
      this.$emit('onHide');
    },
    async loadInquiryVirtualAccount({  } = {}) {
      this.loading.company = true;
      await this.$store.dispatch(INQUIRY_VA, {
        owned_by: this.selectedUser.owned_by,
      });
      this.inquiry_va = this.$store.getters.inquiry_va;
      // console.log('list inquiry_va:', this.inquiry_va);
    },
  },
  watch: {
    isShown(newVal) {
      // this.show = this.isShown;
      this.show = newVal;
      if (newVal) {
        // console.log('Full Name:', this.selectedUser.full_name);
        // console.log('Phone Number:', this.selectedUser.phone_number);
        // console.log('owned by:', this.selectedUser.owned_by);
      }
      if (this.show) {
        this.loadInquiryVirtualAccount();
      }
    },
  },
};
</script>
