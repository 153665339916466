<template>
  <b-modal v-model="show" title="Set As Private User" hide-footer @hide="onHide">
    <el-descriptions class="margin-top mb-3" title="" :column="1" border>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          Name
        </template>
        {{ selectedUser.full_name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-message"></i>
          Email
        </template>
        {{ selectedUser.email }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-phone"></i>
          Phone Number
        </template>
        {{ selectedUser.phone_number }}
      </el-descriptions-item>
    </el-descriptions>
    <b-form @submit.prevent="onSubmit" @reset="onHide" class="mt-2">
      <b-form-group label="Company">
        <el-select @change="handlerChangeSelectCompany" size="small" :loading="loading.company" v-model="v$.form.company_id.$model" placeholder="Select or search company" style="width: 100%" filterable remote :remote-method="searchCompany">
          <el-option
            v-for="item in companies.rows"
            :key="item.company_id"
            :label="item.company_name"
            :value="item.company_id">
          </el-option>
        </el-select>
        <b-form-invalid-feedback :state="!v$.form.company_id.$invalid" v-if="v$.form.company_id.$errors[0]">
          <span> {{ v$.form.company_id.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
    </b-form>
    <p class="mt-2 mb-2" v-if="selected_company.company_name">Are you sure want to add <code>{{ selectedUser.full_name }}</code> as <code>{{ selected_company.company_name }}</code> private user?</p>
    <div class="d-flex float-right">
      <el-button @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
      <el-button @click="onHide" size="small" class="mr-2">Close</el-button>
    </div>
  </b-modal>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { GET_COMPANIES } from '@/store/modules/companies';
import { SINGLE_IMPORT_PRIVATE_USER } from '@/store/modules/app_user';
import popupErrorMessages from '@/library/popup-error-messages';

export default {
  name: 'SingleImportPrivate',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    selectedUser: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        company_id: { required },
      },
    };
  },
  data() {
    return {
      show: false,
      loading: {
        company: false,
        submit: false,
      },
      form: {
        company_id: '',
      },
      companies: {
        rows: [],
        count: 0,
      },
      timeoutSearchCompany: null,
      selected_company: {},
    };
  },
  methods: {
    onHide() {
      this.$emit('onHide');
    },
    async loadCompanies({ search_keyword = '' } = {}) {
      this.loading.company = true;
      await this.$store.dispatch(GET_COMPANIES, {
        search_keyword,
      });
      this.loading.company = false;
      this.companies = this.$store.getters.companies;
    },
    searchCompany(value) {
      clearTimeout(this.timeoutSearchCompany);
      this.timeoutSearchCompany = setTimeout(() => {
        this.loadCompanies({
          search_keyword: value,
        });
      }, 1000);
    },
    onSubmit() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;

      const selected_company = this.companies.rows.find((v) => v.company_id === this.form.company_id);
      this.$confirm(`Are you sure want set this user as a private user for ${selected_company.company_name}?`, 'Confirmation', {
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            this.submitUser();
          }
          done();
        },
      }).catch(() => {});
    },
    async submitUser() {
      this.loading.submit = true;
      await this.$store.dispatch(SINGLE_IMPORT_PRIVATE_USER, {
        id: this.form.company_id,
        data: {
          owned_by: this.selectedUser.owned_by,
        },
      }).then(() => {
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Successfully set user as private',
        });
        this.$parent.getList();
        this.form = {
          company_id: '',
        };
        this.onHide();
      }).catch((err) => {
        if (err.response) {
          popupErrorMessages(err.response);
        }
      });
      this.loading.submit = false;
    },
    handlerChangeSelectCompany(value) {
      this.selected_company = this.companies.rows.find((v) => v.company_id === value);
    },
  },
  watch: {
    isShown() {
      this.show = this.isShown;
      if (this.show) {
        this.loadCompanies();
      }
    },
  },
};
</script>